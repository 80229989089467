





































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { User } from '@/types'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import { CovidSuspensionStatus, SubscriptionStatus } from '@/api'
import { formatDate } from '@/util/formatDate'

@Component({
  components: {
    Content,
    Toolbar
  },
  methods: {
    formatDate
  }
})
export default class Subscription extends Vue {
  get covidSuspensionStatusIsProcessed () {
    return this.subscription?.covidSuspensionStatus === CovidSuspensionStatus.Processed
  }

  get subsciptionIsSuspended () {
    return this.subscription?.subscriptionStatus === SubscriptionStatus.Suspended
  }

  get subscriptionExpires () {
    return this.subscription?.subscriptionStatus === 'Expires'
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscriptionDetails
  }

  get registration () {
    return this.currentUser.registration
  }

  get projectName () {
    return this.currentUser.projectInformation?.name
  }

  get projectExpensiveSubscriptionCheckThreshold () {
    return this.currentUser.projectInformation?.expensiveSubscriptionCheckThreshold
  }

  getSuspendedDate () {
    if (this.currentUser?.subscriptionDetails?.covidSuspensionDate) {
      return formatDate(this.currentUser.subscriptionDetails.covidSuspensionDate)
    }
  }

  get subscriptionEndDate () {
    const date = new Date(this.subscription?.endDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get subscriptionStartDate () {
    const date = new Date(this.subscription?.startDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get subscriptionMinimumEndDate () {
    const date = new Date(this.subscription?.minimumEndDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get suspendFromDate () {
    const date = new Date(this.subscription?.suspendedFrom || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get suspendToDate () {
    const date = new Date(this.subscription?.suspendedTo || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get isSuspensionExceedsSixMonths () {
    const dateTo = new Date(this.subscription?.suspendedTo || '')
    const dateFrom = new Date(this.subscription?.suspendedFrom || '')

    const day = (3600 * 24)
    const temp = new Date(dateFrom.getTime() - day)
    const maxEnd = new Date(temp.setMonth(temp.getMonth() + 6))

    if (maxEnd.getTime() > dateTo.getTime()) {
      return false
    }

    return true
  }

  get subscriptionEndDateBeforeLockdown () {
    const suspendFromDate = new Date(this.subscription?.suspendedFrom || '')
    const suspendToDate = new Date(this.subscription?.suspendedTo || '')
    const endDate = new Date(this.subscription?.endDate || '')
    const diff = Math.abs(suspendFromDate.getTime() - suspendToDate.getTime())
    const endDateBeforeLockdown = new Date(endDate.getTime() - diff)
    return endDateBeforeLockdown.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get cancellationDate () {
    const date = new Date(this.subscription?.cancellationDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get cancellationProcessDate () {
    const date = new Date(this.subscription?.cancellationProcessDate || '')
    return date.toLocaleDateString('nl-NL', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
  }

  get hasActiveSubscription () {
    return (
      this.currentUser.subscription?.subscriptionStatus ===
      SubscriptionStatus.Active
    )
  }

  get canEditSubscription () {
    return this.currentUser?.subscription && this.hasActiveSubscription
  }

  getCovidSuspensionDate () {
    if (this.subscription?.covidSuspensionDate) {
      return formatDate(this.subscription?.covidSuspensionDate)
    }
  }

  isAutoRenewal () {
    let isAutoRenewal = true

    // Supress auto renewal text for Workit free trial period articles.
    // These articles end with a ".Z" code.
    if (this.subscription?.articles) {
      for (const article of this.subscription?.articles) {
        if (article.code?.endsWith('.Z')) {
          isAutoRenewal = false
        }
      }
    }

    return isAutoRenewal
  }

  public back () {
    this.$router.push({ name: 'dashboard' })
  }
}
